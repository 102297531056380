import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CurrencySelector/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/FAButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TranslationProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layouts/header.tsx");
